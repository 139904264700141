<div *minAppversion="2">


  <nz-steps [nzCurrent]="current">
    <nz-step nzTitle="Datei hochladen"></nz-step>
    <nz-step nzTitle="Termine auswählen"></nz-step>
    <nz-step nzTitle="Fertig"></nz-step>
  </nz-steps>

  <nz-divider></nz-divider>

  <ng-container *ngIf="current === 0">
    <div style="margin-top: 15px;">
      <nz-upload
        nzType="drag"
        [nzMultiple]="false"
        [nzAccept]="'.ics'"
        (nzChange)="handleFileUpload($event)"
        [nzShowUploadList]="false"
      >
        <p class="ant-upload-drag-icon">
          <span nz-icon nzType="file"></span>
        </p>
        <p class="ant-upload-text">Klicken Sie hier oder ziehen Sie die ICS-Datei in diesen Bereich, um sie
          hochzuladen</p>
        <p class="ant-upload-hint">
          Es können nur ICS-Dateien hochgeladen werden.
        </p>
      </nz-upload>
    </div>
  </ng-container>


  <ng-container *ngIf="current === 1">
    <div style="margin-top: 15px;">
      <nz-table #eventTable nzSize="small" nzPaginationType="default" [nzData]="events" [nzPageSize]="6"
                [nzPaginationPosition]="'bottom'"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
        <thead>
        <tr>
          <th [nzChecked]="checked" [nzIndeterminate]="indeterminate"
              (nzCheckedChange)="onAllChecked($event)"></th>
          <th>Termin</th>
          <th>Mitarbeiter / Team</th>
          <th>Objekt/Kunde</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let eve of eventTable.data; let i = index">
          <td [nzChecked]="setOfCheckedId.has(eve.id)" (nzCheckedChange)="onItemChecked(eve.id, $event)"></td>
          <td>
            <h6 class="color-orange p-0 m-0">
              <strong>{{eve.summary}}</strong>
            </h6>
            <span> <i nz-icon nzType="calendar" nzTheme="outline"
                      class="m-r-5"></i> {{eve.startDate | date:(eve.allDay ? 'dd.MM.y' : 'dd.MM.y HH:mm ')}}</span>
            <p>
                        <span> <i nz-icon nzType="aim" class="m-r-5"
                                  nzTheme="outline"></i>{{eve.location ? eve.location : '-'}}</span> <br>
              <span> <i nz-icon nzType="info-circle" class="m-r-5"
                        nzTheme="outline"></i>{{eve.description ? eve.description : '-'}}</span>
            </p>
          </td>
          <td>
            <nz-select [(ngModel)]="eve.resource"
                       nzShowSearch class="w-100"
                       nzPlaceHolder="bitte wählen" nzSize="small"
            >
              <nz-option-group nzLabel="Mitarbeiter">
                <nz-option nzCustomContent *ngFor="let employee of users" [nzLabel]="employee.name"
                           [nzValue]="employee">
                  {{employee.name}}
                </nz-option>
              </nz-option-group>
              <nz-option-group nzLabel="Teams" *ngIf="teams?.length > 0">
                <nz-option nzCustomContent *ngFor="let team of teams"
                           [nzLabel]="team.name + ' (Team)'"
                           [nzValue]="team">
                  {{team.name}}
                </nz-option>
              </nz-option-group>

            </nz-select>
            <div style="height: 0px">
              <a *ngIf="i === 0 && eve.resource">
                <small (click)="takeOverForAll(eve.resource)">
                  <i class="m-r-5" nz-icon nzType="plus" nzTheme="outline"></i>
                  Auswahl für alles übernehmen</small>
              </a>
            </div>
          </td>
          <td>
            <app-object-customer-select [optional]="true" [multiple]="false" [hideLabel]="true"
                                        [objects]="objects" [contacts]="contacts"
                                        (referenceChange)="referenceSelected($event, eve)"
                                        [(reference)]="eve.reference"
                                        [allowClear]="true"></app-object-customer-select>
            <div style="height: 0px">
              <a *ngIf="i === 0 && (eve.contact_id || eve.object_id)">
                <small (click)="takeOverReference(eve.object || eve.customer)">
                  <i class="m-r-5" nz-icon nzType="plus" nzTheme="outline"></i>
                  Auswahl für alles übernehmen</small>
              </a>
            </div>

          </td>
        </tr>
        </tbody>
      </nz-table>

      <nz-divider *ngIf="current === 1"></nz-divider>

      <button nz-button nzSize="small" class="m-t-10" nzType="default"
              (click)="pre()">Zurück
      </button>

      <button nz-button nzSize="small" class="m-t-10 pull-right" nzType="primary"
              [disabled]="loading || !isValid()" (click)="save()">
        ({{setOfCheckedId.size}}) Ausgewählte Termine hinzufügen
      </button>
    </div>
  </ng-container>
</div>
